import React from 'react';
import { navigationConfig } from '@/components/layout/navigation.config';
import { useTranslation } from 'next-i18next';
import { useUserStore } from '@bamboo/core-lib/repo/user';
import { useSessionStore } from '@bamboo/core-lib/src/repo/session';
import { YouTube, Instagram } from '@mui/icons-material';

type MenuItem = {
  title: string;
  to: string;
  icon?: React.ReactNode;
};

export const useUserMenu = () => {
  const { t } = useTranslation(navigationConfig.i18nNamespaces);
  const { userProfile } = useUserStore();
  const defaultMenu: MenuItem[] = [
    { title: t('navigation:menu.profile'), to: '/dashboard-user/profile' },
    { title: t('navigation:menu.downloads'), to: '/dashboard-user/downloads' },
    { title: t('navigation:menu.favorites'), to: '/dashboard-user/favorites' },
    { title: t('navigation:menu.help'), to: '/dashboard-user/help' },
  ];

  const filmmakerMenu: MenuItem[] = [
    { title: t('navigation:menu.start'), to: '/dashboard-user/statistics' },
    { title: t('navigation:menu.myClips'), to: '/dashboard-user/clips' },
    { title: t('navigation:menu.upload'), to: '/dashboard-user/upload' },
    { title: t('navigation:menu.sales'), to: '/dashboard-user/sales' },
    { title: t('navigation:menu.downloads'), to: '/dashboard-user/downloads' },
    { title: t('navigation:menu.favorites'), to: '/dashboard-user/favorites' },
    {
      title: t('navigation:menu.profile'),
      to: '/dashboard-user/filmmaker',
    },
  ];

  const isFilmmaker = !!userProfile?.filmID;
  const menu: MenuItem[] = isFilmmaker ? filmmakerMenu : defaultMenu;

  return [menu, userProfile] as [typeof menu, typeof userProfile];
};

export const useTopBarMenu = () => {
  const { t } = useTranslation(navigationConfig.i18nNamespaces);

  const defaultMenu: MenuItem[] = [
    { title: t('navigation:topNav.home'), to: '/' },
    { title: t('navigation:topNav.discover'), to: '/discover' },
    { title: t('navigation:topNav.search'), to: '/search' },
    { title: t('navigation:topNav.studios'), to: '/studios' },
    { title: t('navigation:topNav.playlists'), to: '/playlists' },
    { title: t('navigation:topNav.prices'), to: '/prices' },
    { title: t('navigation:topNav.faq'), to: '/faq' },
  ];

  return [defaultMenu] as [typeof defaultMenu];
};

export const useHomeMenu = () => {
  const { t } = useTranslation(navigationConfig.i18nNamespaces);
  const { jwt } = useSessionStore();
  const { userProfile } = useUserStore();
  const isFilmmaker = !!userProfile?.filmID;

  const defaultMenu: MenuItem[] = [
    { title: t('navigation:topNav.home'), to: '/' },
    { title: t('navigation:topNav.discover'), to: '/discover' },
    { title: t('navigation:topNav.search'), to: '/search' },
    { title: t('navigation:topNav.studios'), to: '/studios' },
    { title: t('navigation:topNav.playlists'), to: '/playlists' },
    { title: t('navigation:topNav.prices'), to: '/prices' },
    { title: t('navigation:topNav.faq'), to: '/faq' },
  ];

  const userMenu: MenuItem[] = [
    {
      title: t('navigation:topNav.myAccount'),
      to: jwt
        ? isFilmmaker
          ? '/dashboard-user/filmmaker'
          : '/dashboard-user/profile'
        : '/auth/login',
    },
    {
      title: t('navigation:topNav.myfavorites'),
      to: jwt ? '/dashboard-user/favorites' : '/auth/login',
    },
    { title: t('navigation:topNav.cart'), to: '/checkout' },
  ];

  const socialMediaMenu: MenuItem[] = [
    {
      title: '',
      icon: <YouTube color="inherit" style={{ width: 18, height: 18 }} />,
      to: 'https://www.youtube.com/channel/UCZYzg6FDtv0Rdbzz_Y5zb9A/videos',
    },
    {
      title: '',
      icon: <Instagram color="inherit" style={{ width: 16, height: 16 }} />,
      to: 'https://www.instagram.com/bamboo.stock/',
    },
  ];

  return [defaultMenu, userMenu, socialMediaMenu] as [
    typeof defaultMenu,
    typeof userMenu,
    typeof socialMediaMenu
  ];
};
