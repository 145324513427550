import type { Clip, Cart, Checkout } from '@bamboo/core-lib/src/api/types';
import { sendGTMEvent } from '@next/third-parties/google';
import {
  calculateItemPrice,
  calculateDiscounts,
} from '@bamboo/core-lib/src/api/flows/cart';

const BrazilianCurrency = 'BRL';
export enum GTMEvents {
  ADDToWishlist = 'add_to_wishlist',
  ViewItemList = 'view_item_list',
  ViewItem = 'view_item',
  SelectItem = 'select_item',
  ADDToCart = 'add_to_cart',
  RemoveFromCart = 'remove_from_cart',
  ViewCart = 'view_cart',
  Purchase = 'purchase',
  BeginCheckout = 'begin_checkout',
}

function getItem(clip: Clip) {
  const item: any = {
    quantity: 1,
    item_id: `SKU_${clip?.sku}`,
    item_name: clip?.title,
    affiliation: 'BambooStock',
    item_brand: 'BambooStock',
    price: clip?.price ?? 0,
    item_category: 'video',
  };

  return item;
}

function getCartItem(prodID: string, cart: Cart) {
  const prod = cart.cartProducts.find((cp) => cp.prodID === prodID);
  const variations = prod?.variations ?? [];
  const price = prod?.price ?? 0;
  const item: any = {
    quantity: 1,
    item_list_id: cart.cartID,
    item_id: `SKU_${prod?.sku}`,
    item_name: prod?.title,
    affiliation: 'BambooStock',
    item_brand: 'BambooStock',
    price: calculateItemPrice(price, variations),
    item_category: 'video',
    coupon: (cart?.cartVouchers ?? []).map((v) => v.code).join(', '),
  };
  variations.forEach((v, i) => {
    item[`item_category${i + 2}`] = v.name;
  });

  return item;
}

function getCartItems(cart: Cart) {
  return (cart?.cartProducts ?? []).map((prod) => {
    const variations = prod?.variations ?? [];
    const price = prod?.price ?? 0;
    const item: any = {
      quantity: 1,
      item_list_id: cart.cartID,
      item_id: `SKU_${prod?.sku}`,
      item_name: prod?.title,
      affiliation: 'BambooStock',
      item_brand: 'BambooStock',
      price: calculateItemPrice(price, variations),
      item_category: 'video',
      coupon: (cart?.cartVouchers ?? []).map((v) => v.code).join(', '),
    };
    variations.forEach((v, i) => {
      item[`item_category${i + 2}`] = v.name;
    });
    return item;
  });
}

function getCheckoutItems(checkout: Checkout) {
  return (checkout?.cartProducts ?? []).map((cp) => {
    const variations = cp?.variations ?? [];
    const price = cp?.price ?? 0;
    const item: any = {
      quantity: 1,
      item_list_id: checkout.cartID,
      item_id: `SKU_${cp.sku}`,
      item_name: cp.title,
      affiliation: 'BambooStock',
      item_brand: 'BambooStock',
      price: calculateItemPrice(price, variations),
      item_category: 'video',
      coupon: (checkout?.cartVouchers ?? []).map((v) => v.code).join(', '),
    };
    variations.forEach((v, i) => {
      item[`item_category${i + 2}`] = v.name;
    });
    return item;
  });
}

export function addToWishlist(clip: Clip) {
  const item = getItem(clip);
  sendGTMEvent({
    event: GTMEvents.ADDToWishlist,
    currency: BrazilianCurrency,
    value: item.price,
    items: [item],
  });
}

export function viewItemList(id: string, name: string, clips: Clip[]) {
  sendGTMEvent({
    event: GTMEvents.ViewItemList,
    item_list_id: id,
    item_list_name: name,
    items: clips.map((c) => getItem(c)),
  });
}

export function viewItem(clip: Clip) {
  const item = getItem(clip);
  sendGTMEvent({
    event: GTMEvents.ViewItem,
    currency: BrazilianCurrency,
    value: item.price,
    items: [item],
  });
}

export function selectItem(id: string, name: string, clip: Clip) {
  sendGTMEvent({
    event: GTMEvents.SelectItem,
    item_list_id: id,
    item_list_name: name,
    items: [getItem(clip)],
  });
}

export function addToCart(prodID: string, cart: Cart) {
  let total = 0;
  (cart?.cartProducts ?? []).forEach((p) => {
    total += calculateItemPrice(p.price, p.variations);
  });
  sendGTMEvent({
    event: GTMEvents.ADDToCart,
    currency: BrazilianCurrency,
    value: total,
    items: [getCartItem(prodID, cart)],
  });
}

export function removeFromCart(prodID: string, cart: Cart) {
  let total = 0;
  (cart?.cartProducts ?? []).forEach((p) => {
    total += calculateItemPrice(p.price, p.variations);
  });
  sendGTMEvent({
    event: GTMEvents.RemoveFromCart,
    currency: BrazilianCurrency,
    value: total,
    items: [getCartItem(prodID, cart)],
  });
}

export function viewCart(cart: Cart) {
  let total = 0;
  const cartProducts = cart?.cartProducts ?? [];
  const cartVouchers = cart?.cartVouchers ?? [];
  cartProducts.forEach((p) => {
    total += calculateItemPrice(p.price, p.variations);
  });
  if (cartVouchers.length > 0) {
    total = calculateDiscounts(total, cartVouchers);
  }

  sendGTMEvent({
    event: GTMEvents.ViewCart,
    currency: BrazilianCurrency,
    value: total,
    items: getCartItems(cart),
  });
}

export function beginCheckout(cart: Cart) {
  let total = 0;
  const cartProducts = cart?.cartProducts ?? [];
  const cartVouchers = cart?.cartVouchers ?? [];
  cartProducts.forEach((p) => {
    total += calculateItemPrice(p.price, p.variations);
  });
  if (cartVouchers.length > 0) {
    total = calculateDiscounts(total, cartVouchers);
  }

  sendGTMEvent({
    event: GTMEvents.BeginCheckout,
    value: total,
    currency: BrazilianCurrency,
    coupon: cartVouchers.map((v) => v.code).join(', '),
    items: getCartItems(cart),
  });
}

export function purchase(checkout: Checkout) {
  let total = 0;
  const cartProducts = checkout?.cartProducts ?? [];
  const cartVouchers = checkout?.cartVouchers ?? [];
  cartProducts.forEach((p) => {
    total += calculateItemPrice(p.price, p.variations);
  });
  if (cartVouchers.length > 0) {
    total = calculateDiscounts(total, cartVouchers);
  }

  sendGTMEvent({
    event: GTMEvents.Purchase,
    value: total,
    currency: BrazilianCurrency,
    transaction_id: checkout.checID,
    coupon: cartVouchers.map((v) => v.code).join(', '),
    items: getCheckoutItems(checkout),
  });
}
