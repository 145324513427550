import React from 'react';
import { styled } from '@mui/material/styles';
import { Dialog, Button, DialogContent, Box, Typography } from '@mui/material';
import api from '@bamboo/core-lib/api';
import { useInlineNotification } from '@bamboo/core-lib/components/Notification';
import AuthLoginForm from '@bamboo/core-lib/components/Auth/LoginForm';
import type {
  LoginFormProps,
  LoginFormValues,
} from '@bamboo/core-lib/components/Auth/LoginForm';
import HiddenComponent from '@bamboo/core-lib/components/HiddenComponent';
import { OnboardingForm } from '@/features/auth/components/OnboardingForm';
import type {
  OnboardingFormProps,
  OnboardingFormValues,
} from '@bamboo/core-lib/components/Auth/OnboardingForm';
import SocialLogin from '@/components/layout/SocialLogin';
import { ApplicationID } from '@bamboo/ts-utils';

const DialogText = styled('h2')(() => ({
  boxSizing: 'border-box',
  padding: '0px',
  margin: '0px',
  marginTop: '0px',
  marginBottom: '15px',
  lineHeight: 1,
  color: 'rgb(53, 53, 53)',
  fontSize: '20px',
  fontWeight: 600,
  whiteSpace: 'break-spaces',
  textAlign: 'center',
}));

const Description = styled('p')(() => ({
  margin: '10px 0',
  marginBottom: '0.9rem',
  boxSizing: 'border-box',
  color: 'rgb(62, 62, 62)',
  fontSize: '15px',
  fontWeight: 400,
}));

const CloseContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  lineHeight: 1,
  color: 'rgb(73, 81, 87)',
  fontSize: '12px',
  marginTop: '0px',
  padding: '20px 20px 0',
  opacity: 1,
}));

const DefaultLabel = {
  loginText: 'Faça seu login',
  onboardingText: 'Crie sua conta',
  onboardingDescription: 'Ainda não tem uma conta?',
  close: 'Fechar',
  noAccount: 'Ainda não tem uma conta?',
  hasAccount: 'Já tem uma conta?',
  create_account: 'Criar minha conta',
  generic_login_error: 'Falha ao realizar login',
  user_already_exists: 'Já existe um usuário com este e-mail',
  generic_onboarding_error: 'Falha ao criar conta',
  user_doesnt_exist: 'Usuário não encontrado',
  loginDescription:
    'Se você já faz parte da família, acesse com uma das opções abaixo:',
  loginFormLabels: undefined as LoginFormProps['labels'],
  onboardingFormLabels: undefined as OnboardingFormProps['labels'],
  social_continue_with: 'Continuar com',
  signing_method_or: 'Ou',
};

export type LoginDialogProps = {
  open: boolean;
  close: (logged: boolean) => void;
  labels?: typeof DefaultLabel;
};

const LoginDialog = (props: LoginDialogProps) => {
  const { open, close, labels = DefaultLabel } = props;
  const [Notification, notify] = useInlineNotification();

  const handleClose = React.useCallback(() => {
    close(false);
  }, [close]);

  const handleSubmit = React.useCallback(
    (formValues: LoginFormValues) => {
      const { username, password, rememberme } = formValues;

      return api
        .post(
          '/api/auth/signin',
          {
            applID: ApplicationID.ecommerce,
            email: username,
            password,
            rememberMe: rememberme,
            deviceID: '',
            deviceName: '',
            ip: '',
            location: '',
            coord: '',
          },
          { withCredentials: true }
        )
        .then(() => close(true))
        .catch((e) => {
          let messagem = labels?.generic_login_error;

          const err = e?.response?.data?.error?.message ?? e?.message ?? e;
          if (err?.indexOf?.('No user with this email') > -1) {
            messagem = labels?.user_doesnt_exist;
          }

          notify({
            message: messagem,
            type: 'error',
            temporary: true,
          });
        });
    },
    [close]
  );

  const handleCreate = React.useCallback((formValues: OnboardingFormValues) => {
    const { username, password, email } = formValues;

    return api
      .authCreateAccount({
        name: username,
        applID: ApplicationID.ecommerce,
        email: email,
        password,
        deviceID: '',
        deviceName: '',
        ip: '',
        location: '',
        coord: '',
      })
      .then(() => close(true))
      .catch((e) => {
        let message = labels?.generic_onboarding_error;
        const err = e?.response?.data?.error?.message ?? e?.message ?? e;
        if (err?.indexOf?.('Already exists user') > -1) {
          message = labels?.user_already_exists;
        }

        notify({
          message: message,
          type: 'error',
          temporary: true,
        });
      });
  }, []);

  const [createAccount, setCreateAccount] = React.useState(false);

  const handleChageForm = () => {
    setCreateAccount((v) => !v);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      style={{
        width: '100%',
      }}
      PaperProps={{
        style: {
          borderRadius: '25px',
          maxWidth: '320px',
          height: 'auto',
          padding: '0px 25px 16px 30px',
        },
      }}
    >
      <CloseContainer>
        <Button
          onClick={handleClose}
          color="inherit"
          style={{
            display: 'inline-block',
            letterSpacing: '1px',
            fontSize: 'inherit',
            fontWeight: 400,
            fontStyle: 'normal',
            lineHeight: 1,
            textTransform: 'none',
            marginTop: '0px',
            marginBottom: '15px',
          }}
        >
          {labels?.close}
        </Button>
      </CloseContainer>
      <DialogContent
        style={{
          paddingTop: '0px',
        }}
      >
        <HiddenComponent hidden={createAccount}>
          <DialogText
            style={{
              textAlign: 'left',
              fontSize: '30px',
              fontWeight: 600,
            }}
          >
            {labels?.loginText}
          </DialogText>
          <Description
            style={{
              fontSize: '15px',
              fontWeight: 400,
            }}
          >
            {labels?.loginDescription}
          </Description>
          <SocialLogin
            labels={{
              google: [labels.social_continue_with, 'Google'],
            }}
            urls={{ google: process.env.OAUTH_LOGIN_URL! }}
          />
          <div
            style={{
              textAlign: 'center',
              overflow: 'hidden',
            }}
          >
            <Typography
              variant="h4"
              style={{
                fontWeight: 600,
              }}
            >
              {labels.signing_method_or}
            </Typography>
          </div>
          <AuthLoginForm
            labels={labels?.loginFormLabels}
            onSubmit={handleSubmit}
          >
            <Box sx={{ mt: 3 }}>
              <Notification />
            </Box>
          </AuthLoginForm>
        </HiddenComponent>
        <HiddenComponent hidden={!createAccount}>
          <DialogText style={{ textAlign: 'left', fontSize: '30px' }}>
            {labels?.onboardingText}
          </DialogText>
          <Description> {labels?.onboardingDescription}</Description>
          <OnboardingForm onSubmit={handleCreate} />
        </HiddenComponent>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={handleChageForm}
            style={{
              textTransform: 'none',
            }}
          >
            {createAccount ? (
              <Typography
                variant="subtitle1"
                color="secondary"
                sx={{
                  boxSizing: 'border-box',
                  textDecoration: 'none',
                  cursor: 'pointer',
                  margin: '0px',
                  marginTop: '0px',
                  marginBottom: '0px',
                  lineHeight: 1,
                  color: '#424242',
                  fontSize: '10px',
                  fontWeight: 400,
                }}
              >
                {labels?.hasAccount}
              </Typography>
            ) : (
              <div
                style={{
                  display: 'flex',
                }}
              >
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  sx={{
                    boxSizing: 'border-box',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    margin: '0px',
                    marginTop: '0px',
                    marginBottom: '0px',
                    marginRight: '3px',
                    lineHeight: 1,
                    color: '#424242',
                    fontSize: '10px',
                    fontWeight: 400,
                  }}
                >
                  {labels?.noAccount}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  sx={{
                    boxSizing: 'border-box',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    margin: '0px',
                    marginTop: '0px',
                    marginBottom: '0px',
                    lineHeight: 1,
                    color: '#727272',
                    fontSize: '10px',
                    fontWeight: 600,
                  }}
                >
                  {labels?.create_account}
                </Typography>
              </div>
            )}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LoginDialog;
