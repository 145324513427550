import Icons from '@bamboo/core-lib/components/Icons';

type SocialLoginProps = {
  labels: { google: string[] };
  urls: { google: string };
};

function SocialLogin({ labels, urls }: SocialLoginProps) {
  return (
    <GoogleSignin url={urls.google}>
      {labels.google?.[0] ?? ''}{' '}
      <b
        style={{
          fontWeight: 'bolder',
          boxSizing: 'border-box',
        }}
      >
        {labels.google?.[1] ?? 'Google'}
      </b>
    </GoogleSignin>
  );
}

export default SocialLogin;

function GoogleSignin({
  children,
  url,
}: {
  children: React.ReactNode;
  url: string;
}) {
  return (
    <>
      <div
        style={{
          boxSizing: 'border-box',
          position: 'relative',
          width: '100%',
          marginBottom: '10px',
        }}
      >
        <div
          style={{
            boxSizing: 'border-box',
            transition:
              'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s, -webkit-border-radius 0.3s ease 0s, -webkit-box-shadow 0.3s ease 0s',
          }}
        >
          <div style={{ boxSizing: 'border-box' }}>
            <div style={{ boxSizing: 'border-box', textAlign: 'left' }}>
              <div
                style={{
                  boxSizing: 'border-box',
                  padding: '5px 0px',
                  flexFlow: 'column',
                  alignItems: 'flex-start',
                  display: 'block',
                }}
              >
                <a
                  href={url}
                  rel="nofollow"
                  style={{
                    backgroundColor: 'transparent',
                    color: 'rgb(204, 51, 102)',
                    boxSizing: 'border-box',
                    border: '0px',
                    flex: '1 1 auto',
                    margin: '5px 0px',
                    display: 'block',
                    width: '100%',
                    textDecoration: 'none',
                    boxShadow: 'none',
                    maxWidth: '100%',
                  }}
                >
                  <div
                    style={{
                      boxSizing: 'border-box',
                      cursor: 'pointer',
                      verticalAlign: 'top',
                      display: 'flex',
                      boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 5px 0px',
                      color: 'rgba(0, 0, 0, 0.54)',
                      backgroundColor: 'rgb(255, 255, 255)',
                      borderRadius: '21px',
                    }}
                  >
                    <div
                      style={{
                        boxSizing: 'border-box',
                        flex: '0 0 auto',
                        padding: '8px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Icons.Google
                        style={{
                          boxSizing: 'border-box',
                          height: '24px',
                          width: '24px',
                          verticalAlign: 'top',
                        }}
                      />
                    </div>
                    <div
                      style={{
                        boxSizing: 'border-box',
                        margin: '0px 24px 0px 12px',
                        padding: '10px 0px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        flex: '1 1 auto',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        fontSize: '16px',
                        lineHeight: '20px',
                        letterSpacing: '0.25px',
                        textAlign: 'center',
                        textOverflow: 'clip',
                        WebkitFontSmoothing: 'antialiased',
                        textTransform: 'none',
                        display: 'inline-block',
                      }}
                    >
                      {children}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
