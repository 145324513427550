import React from 'react';
import { styled } from '@mui/material/styles';
import { Dialog, Button, DialogContent } from '@mui/material';
import api from '@bamboo/core-lib/api';
import { notify } from '@bamboo/core-lib/repo/notification';

const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
  padding: '34px',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px',
  },
}));

const DialogImageActionContainer = styled('div')(() => ({
  padding: '10px',
  width: '200px',
  height: 'auto',
  maxWidth: '220px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

const DialogImageContainer = styled(
  (props: React.HTMLProps<HTMLDivElement> & { image?: string }) => {
    const { image, ...others } = props;
    return <div {...others} />;
  }
)(({ image, theme }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '200px',
  height: '200px',
  marginBottom: '10px',
  textAlign: 'center',
  textSizeAdjust: '100%',
  backgroundImage: `url("${image}")`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '262px 262px',
  backgroundPositionX: 'center',
  backgroundPositionY: 'center',
}));

const DialogText = styled('h2')(({ theme }) => ({
  boxSizing: 'border-box',
  padding: '0px',
  margin: '0px',
  marginTop: '0px',
  marginBottom: '14px',
  lineHeight: 1,
  color: 'rgb(53, 53, 53)',
  fontSize: '17px',
  fontWeight: 600,
  whiteSpace: 'break-spaces',
  textAlign: 'center',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    marginBottom: '10px',
  },
}));

const ActionContainer = styled('div')(({ theme }) => ({
  boxSizing: 'border-box',
  position: 'relative',
  width: '100%',
  marginTop: '17px',
  marginBottom: '0px',
  display: 'flex',
  '& *:nth-child(1)': {
    width: '100%',
  },
  '& *:nth-child(2)': {
    width: '100%',
    marginLeft: '5px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    marginTop: '17px',
    width: '97%',
    justifyContent: 'center',
    '& *:nth-child(1)': {
      width: '100%',
    },
    '& *:nth-child(2)': {
      width: '100%',
      marginLeft: '0',
      marginTop: '10px',
    },
  },
}));

const AcceptedButton = styled(Button)(() => ({
  boxSizing: 'border-box',
  padding: '12px 24px',
  transition: 'all 0.3s ease 0s',
  display: 'inline-block',
  lineHeight: 1,
  fontSize: '15px',
  color: 'rgb(255, 255, 255)',
  fill: 'rgb(255, 255, 255)',
  textAlign: 'center',
  textDecoration: 'none',
  boxShadow: 'none',
  width: '100%',
  fontWeight: 500,
  borderRadius: '50px',
  textTransform: 'none',
  backgroundColor: 'rgb(25, 186, 187)',
  '&: hover': {
    backgroundColor: 'rgb(25, 186, 187)',
    color: 'rgb(255, 255, 255)',
  },
}));

const RefusedButton = styled(Button)(() => ({
  boxSizing: 'border-box',
  padding: '12px 24px',
  transition: 'all 0.3s ease 0s',
  display: 'inline-block',
  lineHeight: 1,
  fontSize: '15px',
  color: 'rgb(255, 255, 255)',
  fill: 'rgb(255, 255, 255)',
  textAlign: 'center',
  textDecoration: 'none',
  boxShadow: 'none',
  width: 'fe',
  fontWeight: 500,
  borderRadius: '50px',
  textTransform: 'none',
  backgroundColor: 'rgb(255, 104, 104)',
  '&: hover': {
    backgroundColor: 'rgb(255, 104, 104)',
    color: 'rgb(255, 255, 255)',
  },
}));

const DefaultLabel = {
  text: 'Sentiremos sua falta.\nDeseja mesmo sair?',
  accept: 'Sim',
  refuse: 'Não',
};

export type LogoutDialogProps = {
  open: boolean;
  close: () => void;
  onLogout?: () => void;
  labels?: typeof DefaultLabel;
};

const LogoutDialog = (props: LogoutDialogProps) => {
  const { open, close, onLogout, labels = DefaultLabel } = props;

  const onAccept = React.useCallback(() => {
    api
      .authSignout({}, {})
      .then(() => {
        onLogout?.();
      })
      .catch((e) => {
        notify({
          message: e?.message,
          type: 'error',
          temporary: true,
        });
        console.warn(e);
      });
    close();
  }, [close, onLogout]);

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      PaperProps={{
        sx: (theme) => ({
          [theme.breakpoints.down('md')]: {
            transform: 'translateX(0px)',
            '& .MuiDialogContent-root': {
              margin: '0px',
            },
          },
        }),
        style: { borderRadius: '25px' },
      }}
    >
      <DialogContentStyled>
        <DialogImageActionContainer>
          <DialogImageContainer image="/assets/green-bye.gif"></DialogImageContainer>
          <DialogText>{labels?.text}</DialogText>
          <ActionContainer>
            <AcceptedButton onClick={onAccept}>{labels?.accept}</AcceptedButton>
            <RefusedButton onClick={close}>{labels?.refuse}</RefusedButton>
          </ActionContainer>
        </DialogImageActionContainer>
      </DialogContentStyled>
    </Dialog>
  );
};

export default LogoutDialog;
