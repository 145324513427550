import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { authConfig } from '@/features/auth/auth.config';
import AuthOnboardingForm from '@bamboo/core-lib/components/Auth/OnboardingForm';
import type { OnboardingFormProps } from '@bamboo/core-lib/components/Auth/OnboardingForm';

export const OnboardingForm = (
  props: Omit<OnboardingFormProps, 'Link' | 'labels'>
) => {
  const { t } = useTranslation(authConfig.i18nNamespaces);

  const labels: OnboardingFormProps['labels'] = {
    email: t('auth:form.email'),
    password: t('navigation:authForm.password'),
    password_confirm: t('navigation:authForm.onboarding_confirm_password'),
    user_name: t('auth:form.user_name'),
    reading_terms: t('forms:commonLabels.readingTerms'),
    terms: t('forms:commonLabels.terms'),
    onboarding_submit: t('auth:form.onboarding_submit'),
    password_error: t('navigation:authForm.onboarding_password_error'),
    email_error: t('navigation:authForm.onboarding_email_error'),
  };

  return <AuthOnboardingForm labels={labels} Link={Link} {...props} />;
};
