import React from 'react';
import { styled } from '@mui/material/styles';
import { CloseRounded } from '@mui/icons-material';
import Power from '@bamboo/core-lib/src/components/Icons/Power';
import { IconButton, Button } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { navigationConfig } from './navigation.config';
import Link from 'next/link';
import type { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import DrawerDialog from '@bamboo/core-lib/src/components/DrawerDialog';
import LogoutDialog from '@/components/layout/LogoutDialog';
import { useTopBarMenu } from '@/lib/hooks/menu';
import { useSessionStore } from '@bamboo/core-lib/repo/session';

const ActionButton = styled(IconButton)(() => ({
  marginRight: '1.7%',
  boxSizing: 'border-box',
  borderRadius: '3px',
  transition: 'all 0.3s ease 0s',
  display: 'inline-block',
  lineHeight: 1,
  textAlign: 'center',
  textDecoration: 'none',
  boxShadow: 'none',
  width: 'auto',
  padding: '0px',
  fontSize: '20px',
  fontWeight: 500,
  fill: 'rgb(150, 150, 150)',
  color: 'rgb(150, 150, 150)',
  backgroundColor: 'rgba(255, 153, 102, 0)',
}));

const Root = styled('div')(() => ({
  position: 'relative',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
  display: 'flex',
  padding: '20px 20px 20px 20px',
  flexDirection: 'column',
}));

const CloseContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  lineHeight: 1,
  color: 'rgb(73, 81, 87)',
  fontSize: '12px',
  marginTop: '0px',
  padding: '15px 15px 0',
  opacity: 1,
}));

const NavContainer = styled('div')(() => ({
  transition:
    'background 0.3s ease 0s, border 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s, border-radius 0.3s ease 0s, box-shadow 0.3s ease 0s',
  display: 'flex',
  MozBoxOrient: 'vertical',
  MozBoxDirection: 'normal',
  flexDirection: 'column',
}));

const List = styled('ul')(() => ({
  border: '0px none',
  outline: '0px',
  background: 'transparent',
  fontSize: '100%',
  verticalAlign: 'baseline',
  boxSizing: 'border-box',
  position: 'relative',
  zIndex: 2,
  listStyle: 'none',
  margin: '0px 0px 0px 0px',
  padding: '0px',
  display: 'block',
  lineHeight: 'normal',
}));

const ListItem = styled('li')(({ theme }) => ({
  border: '0px none',
  outline: '0px',
  background: 'transparent',
  fontSize: '100%',
  verticalAlign: 'baseline',
  boxSizing: 'border-box',
  listStyle: 'none',
  margin: '0px',
  padding: '0px',
  display: 'block',
  marginTop: '0px',
  lineHeight: 'normal',
  position: 'relative',
  borderWidth: '0px',
  marginBottom: '4px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '14px',
  },
}));

const PowerIcon = styled(Power)(({ theme }) => ({
  color: 'black',
  height: 27,
  width: 27,
  [theme.breakpoints.down('md')]: {
    marginTop: '30px',
    paddingLeft: '45px',
  },
}));

const NavLink = styled(
  (props: LinkProps & { selected?: boolean; children: React.ReactNode }) => {
    const { selected, ...other } = props;
    return <Link {...other} />;
  }
)(({ theme, selected }) => ({
  backgroundColor: 'transparent',
  textDecoration: 'none',
  boxShadow: 'none',
  position: 'relative',
  display: 'flex',
  MozBoxAlign: 'center',
  alignItems: 'center',
  color: 'rgb(51, 51, 51)',
  fill: 'rgb(51, 51, 51)',
  ...(selected
    ? {
        color: 'rgb(115, 115, 115)',
        fill: 'rgb(115, 115, 115)',
        textDecorationColor: 'rgb(115, 115,115)',
      }
    : {}),
  [theme.breakpoints.down('md')]: {
    fontSize: '48px',
    fontWeight: 200,
    textTransform: 'lowercase',
    paddingLeft: '45px',
    paddingRight: '45px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
}));

export type SideNavigationProps = {
  color?: string;
  icon: React.ReactNode;
};

const SideNavigation = (props: SideNavigationProps) => {
  const { color, icon } = props;
  const [open, setOpen] = React.useState(false);
  const [logout, setLogout] = React.useState(false);
  const { t } = useTranslation(navigationConfig.i18nNamespaces);
  const router = useRouter();
  const [menu] = useTopBarMenu();
  const { user } = useSessionStore();

  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  const handleLogout = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      if (!user) {
        router.push('/auth/login');
        return;
      }
      setLogout(true);
    },
    [user]
  );

  return (
    <>
      <ActionButton onClick={onOpen} style={color ? { color } : {}}>
        {icon}
      </ActionButton>
      <DrawerDialog open={open} onClose={onClose}>
        <CloseContainer>
          <Button
            onClick={onClose}
            color="inherit"
            sx={{
              fontStyle: 'normal',
              textTransform: 'none',
              fontSize: '12px',
              fontWeight: 300,
              letterSpacing: '1px',
              lineHeight: '12px',
              color: 'rgb(0, 0, 0)',
              '& .MuiButton-endIcon': {
                marginLeft: '0px ',
              },
            }}
            endIcon={<CloseRounded style={{ height: 16, width: 16 }} />}
          >
            {t('common:actions.close')}
          </Button>
        </CloseContainer>
        <Root>
          <NavContainer>
            <List>
              {menu.map((page, i) => (
                <ListItem key={i}>
                  <NavLink
                    href={page.to}
                    selected={router.pathname === page.to}
                  >
                    {page.title}
                  </NavLink>
                </ListItem>
              ))}
            </List>
          </NavContainer>
          <div>
            <IconButton
              onClick={handleLogout}
              sx={(theme) => ({
                [theme.breakpoints.down('md')]: {
                  padding: '0px',
                  fontWeight: 900,
                },
              })}
            >
              <PowerIcon />
            </IconButton>
          </div>
        </Root>
      </DrawerDialog>
      <LogoutDialog
        open={logout}
        onLogout={onClose}
        close={() => setLogout(false)}
        labels={{
          accept: t('common:actions.yes'),
          refuse: t('common:actions.no'),
          text: t('common:logoutText'),
        }}
      />
    </>
  );
};

export default SideNavigation;
