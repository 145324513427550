import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
  TextFieldProps,
  FormHelperText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { PasswordInput, PasswordInputProps } from './PasswordInput';
import { emailValidation } from '@bamboo/ts-utils';

const StyledInput = styled(
  React.forwardRef((props: TextFieldProps, ref: any) => {
    return (
      <TextField
        ref={ref}
        {...props}
        margin="normal"
        size="small"
        inputProps={{ disableUnderline: true, ...props?.inputProps }}
        InputLabelProps={{
          ...props?.InputLabelProps,
        }}
      />
    );
  })
)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderBottom: '1px solid #e0e0e0',
    backgroundColor: 'white',
  },
}));

const StyledPasswordInput = styled(
  React.forwardRef((props: PasswordInputProps, ref: any) => {
    return (
      <PasswordInput
        ref={ref}
        {...props}
        formControlProps={{ margin: 'normal', size: 'small' }}
        disableUnderline={true}
      />
    );
  })
)(({ theme }) => ({
  borderBottom: '1px solid #e0e0e0',

  backgroundColor: 'white',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgb(25, 186, 187)',
  borderRadius: '60px',
  marginTop: '16px',
  textTransform: 'none',
  '&: hover': {
    backgroundColor: 'rgb(25, 186, 187)',
  },
  '&: disabled': {
    backgroundColor: '#9d9494',
    color: 'white',
  },
}));

const FlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  '& > :first-child': {
    marginRight: '10px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    '& > :first-child': {
      marginRight: '0px',
    },
  },
}));

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  backgroundColor: '#e7e7e7',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#e7e7e7;',
  },
  'input:disabled ~ &': {
    background: '#e7e7e7;',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#26bebf',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#26bebf',
  },
});

interface BpCheckboxProps {
  name: string;
  register: Function;
}

const BpCheckbox: React.FC<BpCheckboxProps> = ({ name, register }) => {
  return (
    <Checkbox
      {...register(name, { required: true })}
      sx={{
        paddingLeft: 0,
        paddingRight: (theme) => theme.spacing(0.5),
        '&:hover': { bgcolor: 'transparent' },
      }}
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
    />
  );
};

const loginFormSchema = z.object({
  username: z.string(),
  email: z.string(),
  password: z.string(),
  confirm: z.string(),
  readingTerms: z.boolean(),
});

export const DefaultLabels = {
  email: 'Email',
  password: 'Senha',
  password_confirm: 'Confirmar senha',
  user_name: 'Digite seu nome...',
  reading_terms: 'Li e concordo com o(s)',
  terms: 'termos e condições do site',
  onboarding_submit: 'Criar minha conta',
  password_error:
    'A senha deve conter no mínimo 8 caracteres e ao menos uma letra e um número',
  email_error: 'Email inválido',
};

export type OnboardingFormValues = z.infer<typeof loginFormSchema>;
export type OnboardingFormProps = {
  onSubmit: (formValues: OnboardingFormValues) => Promise<void>;
  children?: React.ReactNode | React.ReactNode[];
  initialValues?: OnboardingFormValues;
  labels?: typeof DefaultLabels;
  Link?: React.ElementType<{ href: any }>;
};

const OnboardingForm = ({
  onSubmit,
  children,
  initialValues,
  labels = DefaultLabels,
  Link,
}: OnboardingFormProps) => {
  const {
    watch,
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<OnboardingFormValues>({
    defaultValues: initialValues,
  });

  const password = watch('password');

  const validateEmail = React.useCallback((value: string) => {
    if (!value) {
      return;
    }
    if (!emailValidation(value)) {
      return labels?.email_error;
    }
    return;
  }, []);

  const validatePassword = React.useCallback((value: any) => {
    if (!value) {
      return;
    }
    const hasDigit = value.match(/[0-9]/gi);
    const hasNonDigit = value.match(/[^0-9]/gi);
    if (!hasDigit || !hasNonDigit || value.length < 8) {
      return labels?.password_error;
    }
    return;
  }, []);

  const validatePasswordConfirm = React.useCallback(
    (value: string) => {
      if (!value || !password) {
        return;
      }
      if (password !== value) {
        return 'Senhas diferentes';
      }
      return;
    },
    [password]
  );

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <StyledInput
        fullWidth
        InputProps={{ disableUnderline: true }}
        variant="standard"
        {...register('username', {
          required: true,
          minLength: 2,
          maxLength: 50,
        })}
        style={{
          marginBottom: '20px',
        }}
        type="email"
        placeholder={labels.user_name}
        error={!!errors.username?.message}
        helperText={errors.username?.message}
      />
      <StyledInput
        fullWidth
        InputProps={{ disableUnderline: true }}
        type={'email'}
        variant="standard"
        {...register('email', {
          required: true,
          minLength: 2,
          maxLength: 50,
          validate: validateEmail,
        })}
        style={{
          marginBottom: '20px',
        }}
        helperText={errors?.email?.message}
        error={!!errors?.email?.message}
        placeholder={labels.email}
      />
      <FlexContainer>
        <StyledPasswordInput
          {...register('password', {
            required: true,
            maxLength: 50,
            validate: validatePassword,
          })}
          id="password"
          placeholder={labels.password}
          error={errors?.password?.message}
        />
        <StyledPasswordInput
          {...register('confirm', {
            required: true,
            maxLength: 50,
            validate: validatePasswordConfirm,
          })}
          id="password-confirm"
          placeholder={labels.password_confirm}
          error={errors?.confirm?.message}
        />
      </FlexContainer>
      <div
        style={{
          marginTop: 20,
          marginLeft: 0,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="subtitle2"
          color="GrayText"
          sx={{ fontWeight: 400 }}
        >
          <BpCheckbox name="readingTerms" register={register} />
          <span>{labels?.reading_terms}</span>
          <span>&nbsp;</span>
          <LinkComponent
            Link={Link}
            style={{ textDecoration: 'none', color: '#26bebf' }}
            href="/terms/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            {labels?.terms}
          </LinkComponent>
        </Typography>
      </div>
      {errors.root && (
        <Box sx={{ mt: 3 }}>
          <FormHelperText error>{errors.root.message}</FormHelperText>
        </Box>
      )}
      {children}

      <Box sx={{ mt: 2, marginBottom: 1 }}>
        <StyledButton
          disableElevation
          fullWidth
          type="submit"
          variant="contained"
          color="secondary"
          style={{
            fontWeight: 700,
            height: '60px',
          }}
        >
          {labels.onboarding_submit}
        </StyledButton>
      </Box>
    </form>
  );
};

type LinkComponentProps = {
  Link?: React.ElementType<any>;
  children: React.ReactNode;
} & React.HTMLProps<HTMLAnchorElement>;

const LinkComponent = (props: LinkComponentProps) => {
  const { Link, ...others } = props;
  if (!!Link) {
    return <Link {...others} />;
  }
  return <a {...others} />;
};

export default OnboardingForm;
